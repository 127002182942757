.Sidebar {
  height: 100%;
  right: 0;
  position: fixed;
  width: 200px;
}

.Sidebar .sidebars {
  position: fixed;
  right: 0px;
  top: 50%;
  transform: translate(0, -50%);
}

.Sidebar .sidebars .bar-wrapper {
  display: block;
  text-align: right;
  cursor: pointer;
  text-decoration: none;
}

.Sidebar .sidebars .bar-wrapper span {
  transform: translate(0, -15px);
  margin-right: 10px;
  display: inline-block;
  opacity: 0;
  transition: opacity 0.45s, color 0.3s;
  color: rgba(62, 75, 115, 0.45);
  font-size: 0.95rem;
}

.Sidebar .sidebars .bar {
  height: 35px;
  width: 5px;
  margin-right: 25px;
  background-color: rgba(62, 75, 115, 0.15);
  transition: background-color 0.3s, margin-right 0.3s;
  margin-bottom: 5px;
  display: inline-block;
}

.Sidebar .sidebars .bar-wrapper:hover .bar {
  background-color: rgba(62, 75, 115, 0.85);
  margin-right: 30px;
}
.Sidebar .sidebars .bar-wrapper:hover span {
  color: var(--sg1);
}

.Sidebar .sidebars .bar-wrapper.inview .bar {
  background-color: rgba(62, 75, 115, 0.85);
}
.Sidebar .sidebars .bar-wrapper.inview span {
  color: var(--sg1);
}

@media only screen and (max-width: 991px) {
  .Sidebar {
    display: none;
  }
}
