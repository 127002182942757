.Projects .projects {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

#unchecked-all-paragraph {
  display: none;
}
