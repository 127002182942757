.ProjectCard img {
  width: 330px;
  margin-top: 15px;
  box-shadow: 0px 2px 8px var(--bx1);
  padding: 5px;
  border-radius: 5px;
}

.ProjectCard a {
  width: 340px;
  box-shadow: 0px 4px 18px var(--bx1);
  padding: 20px 20px 20px 20px;
  border-radius: 10px;
  display: block;
  text-decoration: none;
  color: inherit;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  transition: transform 0.25s;
  color: var(--sg1);
}

.ProjectCard time {
  margin-top: 0;
  margin-right: 5px;
  font-weight: 400;
}

.ProjectCard h3 {
  margin-bottom: 0px;
  margin-top: 10px;
  font-weight: 500;
  font-family: TexGyre;
  font-size: 1.25rem;
}

.ProjectCard p {
  font-size: 0.9em;
}

.ProjectCard > a::before {
  content: '';
  left: 0;
  width: 0%;
  height: 1px;
  bottom: calc(1rem + 2px);
  top: auto;
  position: absolute;
  z-index: 2;
  transition: width 0.25s;
  background-color: var(--b2);
}

.ProjectCard > a:hover::before {
  content: '';
  left: 0;
  width: 100%;
  height: 1px;
  position: absolute;
  z-index: 2;
  transition: width 0.25s;
  background-color: var(--b2);
}

.ProjectCard > a::after {
  content: '';
  left: 0;
  background-color: var(--b2);
  width: 100%;
  height: 1px;
  bottom: calc(1rem + 2px);
  position: absolute;
  z-index: 2;
  transition: width 0.25s;
  background-color: rgba(0, 0, 0, 0.15);
}

.code-circle {
  border-radius: 50%;
  width: 14px;
  height: 14px;

  background-color: rgb(51, 56, 68);
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.code-circle-def {
  vertical-align: 1px;
  margin-right: 5px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: 400;
}

.code-compositions > span {
  display: inline-block;
}

@media only screen and (max-width: 691px) {
  .ProjectCard a {
    width: calc(100% - 80px);
  }
  .ProjectCard img {
    width: calc(100% + 40px);
    box-shadow: none;
    margin-left: -25px;
    margin-right: 15px;
  }
}
