footer {
  background-color: var(--b1);
  height: 150px;
  margin: 0px;
  bottom: 0;
  position: relative;
  width: 100%;
  color: var(--white);
  padding: 0px;
}

footer > #links {
  padding-top: 30px;
  width: 100%;
  text-align: center;
}

footer > #links > a {
  margin-right: 8px;
  margin-left: 8px;
}
footer > #links > a > svg > path {
  fill: #fefefe;
}
