.Home #first-links {
  margin-top: -50px;
}
.Home #heading {
  margin-bottom: 60px;
}
.Home #first-links > p {
  display: inline-block;
  padding-right: 10px;
}
.Home h2 {
  font-size: 1.6rem;
}
.Home #first-links > p > a {
  text-decoration: none;
}
.Home #first-links > p {
  margin: 0;
}
.Home .projects {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}
.Home .publication p {
  margin: 0;
}
.Home .publication {
  margin-bottom: .5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.Home .publication .publication-details {
  max-width: 40rem;
  width: 40rem;
  min-height: 8rem;
}
.Home .publication .publication-image {
  width: 20rem;
  margin-right: 1rem;
}
.Home .publication .publication-image img {
  background-size: contain;
  width: 100%;

}
@media only screen and (max-width: 422px) {
  .Home #logo {
    display: none;
  }
}
@media only screen and (max-width: 354px) {
  .Home #heading {
    line-height: 1.2;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .Home #first-links {
    margin-top: -37px;
  }
}
