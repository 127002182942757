.DefaultLayout > .content {
  width: 80%;
  margin-left: 5%;
  margin-bottom: 70px;
  min-height: -webkit-calc(100vh - 265px);
  min-height: calc(100vh - 265px);
}
@media only screen and (max-width: 991px) {
  .DefaultLayout > .content {
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-top: 75px;
  }
}
@media only screen and (max-width: 510px) {
  .DefaltLayout > .content a:hover::before,
  .DefaultLayout > .content a:focus::before {
    width: 0%;
  }
}
