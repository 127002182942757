.CodeCircles .code-circle {
  border-radius: 50%;
  width: 14px;
  height: 14px;

  background-color: rgb(51, 56, 68);
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.CodeCircles > span {
  display: inline-block;
}

.CodeCircles .code-circle-def {
  vertical-align: 1px;
  margin-right: 5px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: 400;
}
