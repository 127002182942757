:root {
  --bx1: rgba(62, 75, 115, 0.15);
  --bx1t: rgba(62, 75, 115, 0.35);
  --sg0: rgb(36, 41, 51);
  --sg1: rgb(61, 69, 89);
  --b1: #099cec;
  --b1t: rgba(9, 156, 252, 0.15);
  --b2: #107ccc;
  --white: #fefefe;
}

@font-face {
  font-family: 'TexGyre';
  src: url('../fonts/texgyreadventor-bold.otf');
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'TexGyre';
  src: url('../fonts/texgyreadventor-regular.otf');
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Light.ttf');
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Regular.ttf');
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Semibold.ttf');
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
}
html {
  font-size: 13pt;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.6;
  margin: 0px;
  color: var(--sg1);
  box-sizing: border-box;
  scroll-behavior: smooth;
  background-color: #fefefe;
  overflow-wrap: break-word;
}
h1 {
  font-family: 'TexGyre';
  font-size: 3rem;
  font-weight: 500;
}
h2 {
  font-family: 'TexGyre';
  font-size: 2rem;
  font-weight: 500;
}
h3 {
  font-size: 1.6rem;
  font-weight: 400;
}
h4 {
  font-size: 1.2rem;
  font-weight: 400;
}

p, li {
  font-size: 1rem;
  font-weight: 400;
}

a {
  color: var(--b1);
  padding-bottom: 2px;
  position: relative;
  font-style: normal;
  cursor: pointer;
}

.anchor {
  display: block;
  position: relative;
  z-index: -1;
  top: -55px;
}

.checkbox * {
  cursor: pointer;
}
.checkbox {
  margin-bottom: 20px;
  display: inline-block;
  -webkit-transform: translateX(-24px);
  -ms-transform: translateX(-24px);
  transform: translateX(-24px);
}
.checkbox input[type='checkbox'] {
  opacity: 0;
}

.checkbox label {
  position: relative;
  display: inline-block;
  padding-left: 28px;
}
.checkbox label::before,
.checkbox label::after {
  position: absolute;
  content: '';
  display: inline-block;
}
.checkbox label::before {
  height: 22px;
  width: 22px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid var(--b1);
  left: 0px;
  top: 4px;
}
.checkbox label::after {
  height: 5px;
  width: 9px;
  border-left: 2px solid var(--b1);
  border-bottom: 2px solid var(--b1);
  transform: rotate(-45deg);
  left: 7px;
  top: 11px;
}
.checkbox input[type='checkbox'] + label::after {
  content: '';
  opacity: 0;
  transition: opacity 0.2s;
}
.checkbox input[type='checkbox']:checked + label::after {
  content: '';
  opacity: 1;
}

@media only screen and (max-width: 991px) {
  html {
    font-size: 16px;
  }
  h1 {
    font-size: 2.6rem;
    margin: 0px;
  }
  h2 {
    font-family: 'TexGyre';
    font-size: 2rem;
    font-weight: 500;
    margin-top: 0px;
  }
  h3 {
    font-size: 1.378rem;
    font-weight: 400;
    margin-top: 0px;
  }
  h4 {
    font-size: 1.0333rem;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
